import request from '@/utils/request'
// 获取服务商列表
export function getSpIdList(data) {
  return request('post', '/apa/sp/getLoginSpList', data)
}

// 获取图片验证码
export function getImageCode() {
  return request('get', '/apa/user/get/image/code')
}

// 用户登录(获取Token)
export function getToken(data) {
  return request('post', '/apm/user/login', data)
}

// 用户登出
export function getLogout(data) {
  return request('post', '/apa/logout', data)
}

// 用户列表
export function getUserList(data) {
  return request('post', '/apa/user/findUserList', data)
}

// 新增和编辑用户【系统设置】
export function getUserEdit(data) {
  return request('post', '/apa/user/mod', data)
}

// 删除用户【系统设置】
export function getUserDel(data) {
  return request('post', '/apa/user/del', data)
}

// 新增和编辑用户【账户设置】
export function getUserRoleEdit(data) {
  return request('post', '/aps/user/bindShop', data)
}

// 删除账户【账户设置】
export function getUserRoleDel(data) {
  return request('post', '/aps/user/unbindShop', data)
}

// 获取用户信息
export function getMyselfInfo() {
  return request('get', '/apa/user/userInfo')
}
import request from '@/utils/request';
// 用户列表
export function getUserList(data) {
  return request('post', '/apm/user/findUserList', data)
}

// 用户列表
export function getRoleList(data) {
  return request('post', '/apm/role/findRoleList', data)
}

// 发送手机验证码
export function goSendMobileCode(data) {
  return request('post', '/apa/sms/send', data)
}

// 校验验证码
export function goCheckMobileCode(data) {
  return request('post', '/apa/sms/check', data)
}

// 修改密码
export function goChangePassword(data) {
  return request('post', '/apa/user/pcUpdatePassword', data)
}
